<template>
  <div class="orderList">
    <div class="title">
      <span>发票信息</span>
    </div>
    <div class="sub-title">
      <span :class="{h1: true, orange: currentStat === '0'}"><a class="span1" @click="jump('0','invoiceApply')">开票申请</a></span>
      <span :class="{h2: true, orange: currentStat === '01'}"><a class="span1" @click="jump('01','invoiceInfo')">申请中</a></span>
      <span :class="{h2: true, orange: currentStat === '04'}"><a class="span1" @click="jump('04','invoiceInfo')">异常申请</a></span>
      <span :class="{h2: true, orange: currentStat === '02'}"><a class="span1" @click="jump('02','invoiceInfo')">已开票</a></span>
      <span :class="{h2: true, orange: currentStat === '03'}"><a class="span1" @click="jump('03','invoiceInfo')">全部发票</a></span>
      <span :class="{h1: true, orange: currentStat === '5'}"><a class="span1" @click="jump('5','customerInvoice')">开票账号管理</a></span>
    </div>
    <div class="mg-top-20 warning">
      <div class="tips-title">
        <div class="icon">
          <img src="@/assets/warning.png" alt="">
        </div>
        <div class="word">
          <span>温馨提示：</span>
        </div>
      </div>
      <div class="content-word">
        <p>发票信息一致的订单可合并开票。若需查看发票及发票收件人信息，请点击  <span style="text-decoration: underline;" class="colClass span1" @click="jump('5','customerInvoice')">开票账号管理</span></p>
        <p>如有问题可咨询 在线客服<img src="@/assets/msg.png" alt="" style="margin: 0 7px;cursor: pointer" @click="udClick"></p>
        <p>增值税发票开票周期为3-5个工作日，为避免影响报账，请您及时提交开票！</p>
      </div>
    </div>
    <div class="mg-top-20 check-apply" v-if="isShowOpenInvoice">
      <div class="checkbox">
        <el-checkbox :indeterminate="indeterminate" v-model="meFlag" class="span1" @change="checkboxChange">全选</el-checkbox>
      </div>
      <div class="btn" @click="openInvoice" style="cursor: pointer">
        <span ><a>开票</a></span>
      </div>
    </div>
    <div v-if="myStayInvoiceList.length>0">
      <div class="mg-top-20" v-for="(v,index) in myStayInvoiceList" :key="index">
        <tableStatus>
          <div>
            <span>{{v.orderTimeBack}}</span>
            <span style="margin-left: 20px">订单号：{{v.orderNo}}</span>
            <span style="color: #ef922e; padding-left: 7px; padding-right: 15px; cursor: pointer" v-if="false">物流跟踪</span>
            <span style="margin-left: 20px">订单状态：{{v.orderStatus}}</span>
          </div>
        </tableStatus>
        <simpleTableMoreColumn v-for="(y,yIndex) in v.orderGoods" :key="yIndex"
                               class="mg-top-ne-1"
                               :edit="editColumn"
                               @switch="pushList"
                               :index="index"
                               :yIndex="yIndex"
                               orderName="订单号"
                               :checkFlag="checkAll"
                               :showCheckBox="showCheckBox" :bean="y"/>
      </div>
    </div>
    <div v-else>
      <p style="width: 100%; height: 300px; font-size: 28px; line-height: 300px; text-align: center">
        <a>空空如也,赶紧去消费吧</a>
      </p>
    </div>
    <div class="mg-top-20 check-apply" v-if="lookMore">
      <div class="btn" @click="pageList" style="cursor: pointer;margin: auto">
        <span ><a>加载更多...</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import simpleTableMoreColumn from '@/components/simpleTableMoreColumn'
import tableStatus from '@/components/tableStatus'
import { ajaxList, checkIsLunarKnot } from '@/api/personCenter/invoiceapply'
import { mapGetters } from 'vuex'
// import { guid } from '@/util/utils'
export default {
  computed: {
    ...mapGetters(['currentStat', 'currentCustom'])
  },
  methods: {
    showOpen () {
      checkIsLunarKnot({ customerId: this.currentCustom.customerId }).then(res => {
        if (!res.data.data) {
          this.isShowOpenInvoice = true
        }
      })
    },
    udClick () {
      // var url = 'https://cineappo.s4.udesk.cn/im_client/?web_plugin_id=8189&language=zh-cn&group_id=10626&signature=true&randomn=' + guid()
      // this.$store.commit('SET_IFRAME_SRC', url)
      var mpurl = 'https://work.weixin.qq.com/kfid/kfc51fdfa81129546b4'
      window.open(mpurl, '_blank')
    },
    randomn (n) {
      if (n > 21) return null
      return parseInt((Math.random() + 1) * Math.pow(10, n - 1))
    },
    jump (stat, url) {
      this.$store.commit('SET_CURRENT_STAT', stat)
      this.$router.push({ name: url, params: { type: stat } })
    },
    openInvoice () {
      if (this.transferList.length === 0 || !this.transferList) {
        this.$notify({
          title: '警告',
          message: '请选择需要开票记录',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      // 计算本次开票金额
      var moneyTemp = 0
      for (let i = 0; i < this.transferList.length; i++) {
        if (!this.transferList[i].currentInvoiceAmount || this.transferList[i].currentInvoiceAmount === 0) {
          this.$notify({
            title: '警告',
            message: '请输入选中记录的开票金额',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        if (this.transferList[i].currentInvoiceAmount > this.transferList[i].notTranslateMoney) {
          this.$notify({
            title: '警告',
            message: '选中记录中包含开票金额超出未开票金额',
            type: 'warning',
            duration: 2000
          })
          return false
        }
        moneyTemp += parseFloat(this.transferList[i].currentInvoiceAmount.toFixed(2))
      }
      this.$router.push({ name: 'invoiceOperate', params: { type: '05', invoiceListTemp: this.transferList, moneyTemp: moneyTemp.toFixed(2) } })
    },
    pageList () {
      this.pageInfo.pageNo = this.pageInfo.pageNo + 1
      ajaxList(this.pageInfo.pageNo, this.pageInfo.pageSize, { customerId: this.currentCustom.customerId, customerName: this.currentCustom.customerName }).then((res) => {
        this.pageInfo.total = res.data.count
        for (let i = 0; i < res.data.data.length; i++) {
          this.myStayInvoiceList.push(res.data.data[i])
        }
        this.checkboxChange(false)
        this.meFlag = false
        this.lookMore = this.pageInfo.total > this.myStayInvoiceList.length
      })
    },
    ajaxList (pageInfo) {
      ajaxList(pageInfo.pageNo, pageInfo.pageSize, { customerId: this.currentCustom.customerId, customerName: this.currentCustom.customerName }).then((res) => {
        this.myStayInvoiceList = res.data.data
        this.pageInfo.total = res.data.count
        this.lookMore = this.pageInfo.total > this.myStayInvoiceList.length
        // 初始化选择框的值
        for (let i = 0; i < this.myStayInvoiceList.length; i++) {
          // var temp = []
          for (let j = 0; j < this.myStayInvoiceList[i].orderGoods.length; j++) {
            // temp.push(false)
            this.step++
          }
          // this.checkData.push(temp)
        }
      })
    },
    pushList (index, yIndex, flag) {
      if (flag) {
        if (this.step !== 1) {
          this.indeterminate = true
        } else {
          this.meFlag = true
          this.indeterminate = false
        }
        this.transferList.push(this.myStayInvoiceList[index].orderGoods[yIndex])
      } else {
        this.meFlag = false
        this.checkAll = false
        for (let i = 0; i < this.transferList.length; i++) {
          if (this.transferList[i].id === this.myStayInvoiceList[index].orderGoods[yIndex].id) {
            this.transferList.splice(i, 1)
          }
        }
        if (this.transferList.length === 0) {
          this.indeterminate = false
        } else {
          this.indeterminate = false
        }
      }
    },
    checkboxChange (flag) {
      if (flag) {
        this.indeterminate = false
        this.checkAll = true
        for (let i = 0; i < this.myStayInvoiceList.length; i++) {
          for (let j = 0; j < this.myStayInvoiceList[i].orderGoods.length; j++) {
            this.transferList.push(this.myStayInvoiceList[i].orderGoods[j])
            // this.checkData[i][j] = true
          }
        }
      } else {
        this.indeterminate = false
        this.checkAll = false
        this.transferList = []
        // for (let i = 0; i < this.checkData.length; i++) {
        //   for (let j = 0; j < this.checkData[i].length; j++) {
        //     // this.checkData[i][j] = false
        //   }
        // }
      }
    }
  },
  components: {
    simpleTableMoreColumn,
    tableStatus
  },
  created () {
    this.$store.commit('SET_CURRENT_STAT', '0')
    this.showOpen()
    this.ajaxList(this.pageInfo)
  },
  mounted () {
    // this.validateField()
  },
  data () {
    return {
      stat: '0',
      editColumn: true,
      showCheckBox: true,
      myStayInvoiceList: [],
      checkAll: false,
      step: 0,
      meFlag: false,
      isShowOpenInvoice: false,
      lookMore: false,
      indeterminate: false,
      transferList: [],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.span1:hover {
  cursor: pointer;
  color: #ef922e;
}
.orderList {
  text-align: left;
  width: 982px;
  box-sizing: border-box;
  background-color: white;
  padding: 38px 40px 110px 40px;
  .title {
    color: #333333;
    font-size: 24px;
  }
  .sub-title {
    margin-top: 30px;
    span {
      margin-right: 20px;
    }
    .h1 {
      color: #333333;
      font-size: 16px;
    }
    .h2 {
      font-size: 14px;
      color: #333333;
    }
    .orange {
      color: #ef922e;
    }
  }
  .warning {
    height: 152px;
    background-color: #F7F3D8;
    padding: 21px 18px 0 18px;
    color: #9A6406;
    .tips-title {
      display: flex;
      align-items: center;
      .word {
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .content-word {
      margin-top: 17px;
      margin-left: 26px;
      line-height: 30px;
    }
  }
  .check-apply {
    display: flex;
    align-items: center;
    .btn {
      margin-left: 15px;
      width: 90px;
      height: 30px;
      background: #ef922e;
      border-radius: 2px;
      line-height: 30px;
      text-align:center;
      color: white;
    }
  }
  .sperate {
    color: #999999;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 30px;
  }
  .form-div {
    margin-top: 30px;
    margin-left: -10px;
  }
  .button-ope {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .btn {
      width: 123px;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      line-height: 40px;
      text-align: center;
      color: #666666;
      font-size: 14px;
      &.active {
        background-color: #ef922e;
        color: white;
        margin-right: 15px;
      }
    }
  }
}
.item-div {
  width: 342px;
  position: relative;
}
.invoice-content {
  display: flex;
  align-items: center;
  .btn {
    width: 166px;
    height: 40px;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    &.active {
      border-color: #ef922e;
      color: #ef922e;
      margin-left: 15px;
    }
  }
}
</style>
