import request from '@/router/axios'

export const ajaxList = (pageNo, pageSize, params) => {
  return request({
    url: '/cineplex/homePagePersonalCenter/myStayInvoiceList',
    method: 'get',
    params: {
      ...params,
      pageNo,
      pageSize
    }
  })
}

export const checkIsLunarKnot = (data) => {
  return request({
    url: '/cineplex/homePage/checkIsLunarKnot',
    method: 'post',
    data: data
  })
}
